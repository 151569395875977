<template>
<v-app>
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <v-toolbar light color="tertiary">
                <v-toolbar-title>Modifique su contraseña</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field v-on:keyup.enter="recoverPassword" id="password1" prepend-icon="mdi-lock" name="password1" label="Nueva contraseña" :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'" @click:append="() => (value = !value)" :type="value ? 'password' : 'text'" v-model="putData.pass1"></v-text-field>
                  <v-text-field v-on:keyup.enter="recoverPassword" id="password2" prepend-icon="mdi-lock" name="password2" label="Confirmar contraseña" :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'" @click:append="() => (value = !value)" :type="value ? 'password' : 'text'" v-model="putData.pass2"></v-text-field>
                </v-form>
                <p>{{ message }}</p>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="goLogin" text light color="tertiary">Inicia sesión</v-btn>
                <v-btn @click="recoverPassword" :disabled="disabled == true" light color="tertiary">Aceptar</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import {BASE_URL} from './constants.js'
export default {
  name: 'RecoverPass',
  data: () => ({
    value: true,
    message: '',
    disabled: 'false',
    putData: {
      pass1: '',
      pass2: ''
    }
  }),
  methods: {
    async recoverPassword() {
      const urlParams = new URLSearchParams(document.location.search);
      const token = urlParams.get('token');
      const email = urlParams.get('email');
      if (!token || !email) {
        this.message = "El enlace no es válido";
        return;
      }

      const pass = this.putData.pass1;
      const repeatPass = this.putData.pass2;
      if (!pass) {
        this.message = "Introduzca una contraseña válida";
        return;
      }
      if (pass != repeatPass) {
        this.message = "Las contraseñas no coinciden";
        return;
      }

      // Everything ok
      this.message = "";
      const unexpectedError = "Ha ocurrido un error inesperado, si el problema persiste contacte con el administrador";

      // Send request
      var putData = {
        email: email,
        token: token,
        password: pass
      };
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+this.$store.state.auth
      };

      const response = await fetch(
        `${BASE_URL}/api/user/password`,
        {
          method: 'PUT',
          headers: headers,
          body: JSON.stringify(putData),
        }
      );
      switch (response.status) {
        case 200:
          this.disabled = true;
          this.message = "Su contraseña ha sido modificada con éxito";
          break;
        default:
          this.disabled = true;
          this.message = unexpectedError;
          break;
      }
    },
    goLogin(){
      this.$router.push({name: 'Login'});
    }
  }
}
</script>
<style scoped>
main {
  background-image: url("../assets/fondoLogin.jpg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
}
</style>