import {BASE_URL} from './constants.js'
/**
 * Do a generic get request to retrive a resource
 * @param jwt the token that authorizes to read the resource
 * @param resource string with the name of the resource to get
 */
async function genericReload(jwt, resource) {
  const response = await fetch(
    `${BASE_URL}/${resource}`,
    {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${jwt}`,
      }
    }
  );
  return response;
}

export default genericReload;