<template>
  <v-navigation-drawer app dark class="primary"
    v-model="drawer"
    :mini-variant.sync="mini"
    permanent
  >
    <v-list-item class="px-2" style="height:64px">
      <v-list-item-avatar>
        <v-icon>mdi-account-circle</v-icon>
      </v-list-item-avatar>

      <v-list-item-title>{{ user }}</v-list-item-title>

      <v-btn
        icon
        @click.stop="mini = !mini"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense>
      <v-list-item router :to="item.to" 
        v-bind:style=" item.title=='Salir' ? 'position: absolute; bottom: 0px; width: 100%' : '' "
        v-for="item in items"
        :key="item.title"
        link
      >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
  import genericReload from '../components/genericReload'
  export default {
    data () {
      return {
        drawer: false,
        user: '',
        items: [],
        mini: true,
      }
    },
    mounted() {
      this.getUser();
    },
    methods: {
      async getUser(){
        let ruta = 'api/user/'+this.$store.state.idUser;
        const response = await genericReload(this.$store.state.auth, ruta);
        if (response.status === 200) {
          const json = await response.json();
          this.user = json[0].nombre;
          const type = json[0].tipo;
          this.$store.commit('modifyNameUser',json[0].nombre);
          this.$store.commit('modifyTypeUser',type);
          switch (type) {
            case "admin":
              this.items = [
                { title: 'Inicio', icon: 'mdi-home-analytics', to: 'inicio'},
                { title: 'Proyectos', icon: 'mdi-account-group', to: 'proyectos'},
                { title: 'Proveedores', icon: 'mdi-table-account', to: 'proveedores'},
                { title: 'Panel de administración', icon: 'mdi-playlist-check', to: 'admin'},
                { title: 'Salir', icon: 'mdi-logout', to: '/'},
              ]
              break;
            case "coord":
              this.items = [
                { title: 'Inicio', icon: 'mdi-home-analytics', to: 'inicio'},
                { title: 'Proyectos', icon: 'mdi-account-group', to: 'proyectos'},
                { title: 'Proveedores', icon: 'mdi-table-account', to: 'proveedores'},
                { title: 'Salir', icon: 'mdi-logout', to: '/'},
              ]
              break;
            default:
              this.items = [
                { title: 'Inicio', icon: 'mdi-home-analytics', to: 'inicio'},
                { title: 'Proyectos', icon: 'mdi-account-group', to: 'proyectos'},
                { title: 'Proveedores', icon: 'mdi-table-account', to: 'proveedores'},
                { title: 'Salir', icon: 'mdi-logout', to: '/'},
              ]
              break;
          }
        }else{
          this.$router.push({name: 'Login'});
        }
      }
    }
  }
</script>
<style>
  .v-list-item{
    margin-left: 5px;
  }
  .v-list-item--active{
    background: #fff !important;
    color: #000 !important;
    border-radius: 3px 0px 0px 3px;
  }
  .v-list-item--link:before{
    border-radius: 3px 0px 0px 3px !important;
  }
  .v-list-item--active:before{
    opacity: 0 !important;
  }
</style>