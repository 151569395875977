<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
      v-if="$route.name != 'Login' && $route.name != 'RecoverPass'"
    >
      <div class="d-flex align-center">
        <v-img
          alt="Volintec Logo"
          class="shrink mr-2"
          contain
          src="@/assets/logo2.png"
          transition="scale-transition"
          width="60"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="https://volintec.es/"
        target="_blank"
        text
      >
        <span class="mr-2">Volintec.es</span>
        <v-icon>mdi-web</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <NavBar v-if="$route.name != 'Login' && $route.name != 'RecoverPass' && $route.name != 'Newsletter'" />
      <v-container class="cMain" fluid>
        <keep-alive v-if="this.$store.state.keepAlive" include="Clientes">
          <router-view />
        </keep-alive>
        <router-view v-else />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import {BASE_URL} from '@/components/constants.js'
export default {
  name: 'App',
  components: {
    NavBar
  },
  mounted(){
    if(this.$store.state.auth && this.$store.state.auth != ""){
      this.checkJWT();
    }else{
      this.$store.commit('modifyAuth','');
    }
  },
  methods: {
    async checkJWT(){
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+this.$store.state.auth
      };
      const response = await fetch(
        `${BASE_URL}/api/auth/checkJWT`,
        {
          method: 'POST',
          headers: headers,
        }
      );
      if(response.status == 200){
        await this.$store.dispatch('loadData');
      }else{
        this.$store.commit('modifyAuth','');
        this.$router.push({name: 'Login'});
      }
    },
  },
};
</script>
<style>
.cMain{
  padding: 0px !important;
}
.botonV{
  border: 4px solid #000 !important;
  padding: 23px 29px !important;
  background-color: transparent !important;
  color: #000 !important;
  margin-right: 2%;
  margin-top: 2%;
}
.botonV:hover{
  border: 4px solid #a52e44 !important;
  background: #a52e44 !important;
  color: #fff !important;
}
.botonVmini{
  border: transparent !important;
  background-color: transparent !important;
  color: #000 !important;
  width: 100% !important;
  height: 60% !important;
}
.botonVmini:hover{
  color: #a52e44 !important;
}
.botonVmini2{
  border: transparent !important;
  background-color: transparent !important;
  color: #000 !important;
  margin-right: 2%;
  margin-top: 2%;
}
.botonVmini2:hover{
  color: #a52e44 !important;
}
.insideCard{
  padding: 20px;
}
.v-input--is-disabled, .v-input--is-disabled input, .v-input--is-disabled textarea {
  color: rgba(0,0,0,.87) !important;
}
.v-label--is-disabled {
  color: rgba(0,0,0,.87) !important;
}
.v-select__selection--disabled {
  color: rgba(0,0,0,.87) !important;
}
</style>