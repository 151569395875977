import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VNavigationDrawer,{staticClass:"primary",attrs:{"app":"","dark":"","mini-variant":_vm.mini,"permanent":""},on:{"update:miniVariant":function($event){_vm.mini=$event},"update:mini-variant":function($event){_vm.mini=$event}},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VListItem,{staticClass:"px-2",staticStyle:{"height":"64px"}},[_c(VListItemAvatar,[_c(VIcon,[_vm._v("mdi-account-circle")])],1),_c(VListItemTitle,[_vm._v(_vm._s(_vm.user))]),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.mini = !_vm.mini}}},[_c(VIcon,[_vm._v("mdi-chevron-left")])],1)],1),_c(VDivider),_c(VList,{attrs:{"dense":""}},_vm._l((_vm.items),function(item){return _c(VListItem,{key:item.title,style:(item.title=='Salir' ? 'position: absolute; bottom: 0px; width: 100%' : ''),attrs:{"router":"","to":item.to,"link":""}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.title))])],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }